import React from "react"
import { SiteMetadata } from "../components"
import { Layout } from "../layouts/Layout"
import { Container, Row, Col} from "react-bootstrap"
import { Link } from "gatsby"

export default () => {
  return (
    <Layout>
      <SiteMetadata title="Ошибка 404" description="Страница не найдена..." canonical="/404/" />
      <Container className="my-3">
        <Row className="text-center">
          <Col xs={12} md={5}>
            <div className="d-inline">Ошибка</div>
            <div style={{fontSize: '10rem'}}>404</div>
          </Col>
          <Col>
            <div className="mb-2">К сожалению запрашиваемая Вами страница не найдена...</div>
            <Link to="/">Главная</Link>          
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
